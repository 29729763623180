#delivery-rule-form label {
  font-size: 12px !important;
}
#delivery-rule-form input {
  font-size: 12px !important;
  width: 100px !important;
}

.min-registration-time-unit {
  display: inline-block;
  margin-left: 5px;
  font-size: 12px;
}
